import { Box, Divider, Theme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { PaymentExtraDataModel, Publication } from 'src/app/models';
import { ProductPaymentMethodsInstallments } from './ProductPaymentMethodsInstallments';
import { renderEmiter } from './RenderEmiter';

interface ProductPaymentMethodsEmittersProps {
  bank: any;
  installment: number;
  interest: number;
  alterPrice: PaymentExtraDataModel;
  amount: number;
  data: Publication;
  discount: number;
  originalAmount: number;
}

export const ProductPaymentMethodsEmitters = ({ bank, installment, interest, alterPrice, data, amount, discount, originalAmount }: ProductPaymentMethodsEmittersProps) => {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      <ProductPaymentMethodsInstallments amount={amount} installment={installment} interest={interest} alterPrice={alterPrice} data={data} discount={discount} originalAmount={originalAmount} />
      <Box display="flex" flexDirection={isXs ? 'row' : ''} flexWrap="wrap">
        {bank.map((option: any) => (
          <>{renderEmiter(option.emiter_img_url, option.card_id.toString(), isDownSm)}</>
        ))}
      </Box>
      <Divider />
    </>
  );
};
