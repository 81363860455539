import { Grid } from '@material-ui/core';
import React from 'react';
import { PaymentExtraDataModel, Publication } from 'src/app/models';
import { PriceView } from 'src/commons/components/PriceView';

interface ProductPaymentMethodsInstallmentsProps {
  installment: number;
  interest: number;
  alterPrice: PaymentExtraDataModel;
  data: Publication;
  amount: number;
  discount: number;
  originalAmount: number;
}

export const ProductPaymentMethodsInstallments = ({
  amount,
  installment,
  interest,
  alterPrice,
  data,
  discount,
  originalAmount
}: ProductPaymentMethodsInstallmentsProps) => {
  // eslint-disable-line arrow-body-style
  const discountPercentage = discount > 0 ? (discount / originalAmount) * 100 : 0;
  return (
    <>
      <Grid container direction="row" alignItems="center">
        <PriceView
          variant="subtitle1"
          key={`${alterPrice.amountTotal}-best-promos`}
          id={`${data.id}-best-promos`}
          beforeText={`${installment > 1 ? `${installment} ${alterPrice.typePayment}` : '1 pago'} de`}
          afterText={
            interest > 0
              ? discount > 0
                ? `fijas ${discountPercentage.toFixed(0)}% off'`
                : 'fijas'
              : discount > 0
              ? `sin interes ${discountPercentage.toFixed(0)}% off`
              : 'sin interes'
          }
          withDecimals={installment > 1}
          price={
            installment === 1
              ? `${amount}`
              : `${
                  interest > 0
                    ? (alterPrice.original_amount + interest) / installment
                    : discount > 0
                    ? amount / installment
                    : alterPrice.original_amount / installment
                }`
          }
          priceProps={{ color: interest > 0 ? '#000' : 'success.dark', fontWeight: 'fontWeightBold' }}
          afterProps={{
            color: interest > 0 ? '#000' : 'success.dark',
            fontWeight: 'fontWeightBold',
            pl: alterPrice.installments > 1 ? 2 : 0.5,
          }}
        />
      </Grid>
    </>
  );
};
