import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { payMethodTableColumnStyles, payMethodTableRowStyles } from 'src/app/views/styles';
import { PaymentModelWithCustom } from 'src/app/models/Payment';
import { ConfigValuesPublication, Shipment } from 'src/app/models';
import { PriceView } from 'src/commons/components/PriceView';
import { MinPriceFreeShipping } from 'src/commons';
import { PaymentModel } from '../app/models/PaymentModel';
import { formatPriceNotDecimal, getDiscountPublication, getInterest } from './productHelpers';

interface PaymentConfig extends PaymentModel {
  config: ConfigValuesPublication['showPrice'];
  isCatamarca: boolean;
  shipment: number;
  shipmentInfo: Shipment;
}

const columnsPayments = [
  {
    id: 'installments',
    label: 'CUOTAS',
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => 
    
        <PriceView
          variant="body2"
          beforeText={`${value} cuota${value > 1 ? 's' : ''} de`}
          withDecimals={row.installments > 1}
          price={((row.amount - row.shipmentInfo.cost) / value).toString()}
        />
      
    
  },
  {
    id: 'original_amount',
    label: 'INTERÉS',
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
      const discount = getDiscountPublication(row.amount.toString(), value);
      const interests = getInterest(value, row.amount);
      return (
        <Typography variant="body2">
          <Box component="text" color={interests === 0 || discount > 0 ? 'success.dark' : 'textPrimary'}>
            {row.isCatamarca ? '' : interests !== 0 && discount <= 0 ? `$${formatPriceNotDecimal(interests)}` : 'Sin interés'}
          </Box>
        </Typography>
      );
    },
  },
  {
    id: 'original_amount',
    label: 'DESCUENTO',
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
      const discount = getDiscountPublication((row.reference === '19' ? row[row.config.main_amount] : row.amount).toString(), value);
      return (
        <Typography variant="body2">
          <Box component="text" color={discount <= 0 ? 'success.dark' : 'textPrimary'}>
            {discount > 0 && `${discount}%`}
          </Box>
        </Typography>
      );
    },
  },
  {
    id: 'original_amount',
    label: 'PRECIO',
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
      const discount = value - row.amount
      const precioOriginal = value - discount
      return (
        <Typography variant="body2" align="right">{`$${formatPriceNotDecimal( 
          precioOriginal > 0 ? (precioOriginal - row.shipmentInfo.cost) : (value - row.shipmentInfo.cost)
        )}`}</Typography>
      )
    }
  },
  {
    id: 'shipment',
    label: 'ENVIO',
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
     const isFreeShipping = row.amount < MinPriceFreeShipping ? value : 0;
      return (
        <Typography variant="body2" align="right">
           {row.shipmentInfo.cost 
          ? `$${formatPriceNotDecimal(row.shipmentInfo.cost - isFreeShipping)}`
          : 0}
        </Typography>
      );
    },
  },
  {
    id: 'amount',
    label: 'TOTAL',
    hideSortIcon: true,
    render: (value: number, row: PaymentConfig) => {
      const isFreeShipping = row.amount < MinPriceFreeShipping ? row.shipment : 0;
      return (
        <Typography variant="body2" align="right">{`$${formatPriceNotDecimal(
          row.isCatamarca && row.reference === '19' ? row[row.config.main_amount] - isFreeShipping : value - isFreeShipping,
        )}`}</Typography>
      );
    },
  },
];

export const addStylesWithPayments = (payments: PaymentModel[]): PaymentModelWithCustom[] =>
  payments.reduce((paymentsAll: PaymentModelWithCustom[], payment: PaymentModel) => {
    const copyPaymentsAll = [...paymentsAll];
    copyPaymentsAll.push({
      ...payment,
      customStyles: payMethodTableRowStyles,
    });
    return copyPaymentsAll;
  }, []);

export const columnsPaymentsWithStyles = columnsPayments.map((column) => ({
  ...column,
  customStyles: payMethodTableColumnStyles,
}));
